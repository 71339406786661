<template>
  <div>
    <div style="background: #ffffff; margin-top: 10px" v-if="!detailStatus">
      <van-empty description="商品不存在">
        <van-button
          round
          type="danger"
          style="width: 120px"
          @click="goBackPage()"
        >
          返回
        </van-button>
      </van-empty>
    </div>
    <div class="container" v-else>
      <!-- 新的 -->
      <div class="goods">
        <el-row :gutter="10">
          <el-col :span="18">
            <div class="goods-left">
              <div class="goods-info">
                <el-row :gutter="10">
                  <el-col :span="8">
                    <div class="goods-info-left">
                      <div class="goods-info-images">
                        <div class="goods-info-images-main">
                          <el-carousel>
                            <el-carousel-item
                              v-for="(v, k, i) in productDetail.pictureList"
                              :key="i"
                              style="
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border: 1px solid #ece0e0;
                                height: 300px;
                              "
                            >
                              <el-image
                                @click="handleImageClick(v.url)"
                                :src="v.url"
                                :preview-src-list="srcList"
                              >
                              </el-image>
                            </el-carousel-item>
                          </el-carousel>
                        </div>
                        <!---->
                      </div>
                      <div class="goods-info-show">
                        <div class="left">
                          <span class="label">商品编号</span>
                          <span class="text">{{ productDetail.id }}</span>
                        </div>
                        <div class="right">
                          <el-tag
                            type="success"
                            size="medium"
                            @click="shareProducts()"
                            effect="plain"
                            style="margin: 5px"
                          >
                            <i class="el-icon-share"></i> 分享商品
                          </el-tag>
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="16">
                    <div class="goods-info-right">
                      <h1 class="goods-info-title">
                        {{ productDetail.productName }}
                      </h1>
                      <div class="goods-info-tags">
                        <!-- <div v-if="productDetail.salsCount">
                          已售:
                          <span style="color: rgb(76, 175, 80)">
                            {{ productDetail.salsCount }}
                          </span>
                        </div> -->
                        <!-- 标签 -->
                        <!-- <el-tag
                          effect="dark"
                          size="medium"
                          :color="productDetail.productTypeTextColor"
                        >
                          {{ productDetail.productTypeText }}
                        </el-tag> -->
                      </div>
                      <div class="goods-info-point">
                        <div class="goods-info-point-left">
                          <div class="label">￥</div>
                          <div class="money">{{ productDetail.price }}</div>
                          <div
                            v-if="productDetail.parValue"
                            style="
                              margin-left: 20px;
                              font-size: 14px;
                              color: #ffffff;
                              text-decoration: line-through;
                            "
                          >
                            原价：{{ productDetail.parValue }}
                          </div>
                        </div>

                        <!-- 等级 -->
                        <div
                          style="cursor: pointer"
                          class="goods-info-point-right"
                          v-if="setting.levelPrice"
                          @click="openLevelVisible"
                        >
                          <div class="sales">提升等级 享受更低折扣</div>
                        </div>

                        <!-- 销量 -->
                        <div
                          class="goods-info-point-right"
                          v-if="productDetail.salsCount"
                        >
                          <div class="sales">
                            销量： {{ productDetail.salsCount }}
                          </div>
                        </div>
                      </div>

                      <!----><!---->
                      <div class="goods-info-item">
                        <div class="label">商品服务：</div>
                        <div class="wrap">
                          <div class="goods-info-guarantee">
                            <div class="item">限时优惠</div>
                            <div class="item">极速发货</div>
                            <div class="item">安全保证</div>
                            <div class="item">售后支持</div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="goods-info-item"
                        v-if="setting.dealQuantityStatus"
                      >
                        <div class="label">下单数量：</div>
                        <div class="wrap">
                          <div class="goods-info-guarantee">
                            <div class="item1">
                              最小下单：{{ productDetail.dealQuantityMin }}
                            </div>
                            <div class="item1">
                              最大下单：{{ productDetail.dealQuantityMax }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- 规格 -->

                      <div
                        class="goods-info-item"
                        style="align-items: normal"
                        v-if="productDetail.skuList.length > 0"
                      >
                        <div class="label">商品规格：</div>
                        <div class="wrap">
                          <div class="skuCate">
                            <div class="skuItemWrapper">
                              <div
                                class="skuItem"
                                v-for="(v, k, i) in productDetail.skuList"
                                :key="i"
                                :class="{
                                  sku_select: selectSkuId === v.productId,
                                }"
                                @click="selectSkuId = v.productId"
                              >
                                <div @click="toProductDetails(v.productId)">
                                  <img
                                    class="skuIcon"
                                    :src="v.productImg"
                                    style="opacity: 1; object-fit: contain"
                                  />
                                  <span class="skuValueName">
                                    {{ v.skuKeyName }}
                                  </span>
                                </div>
                                <!---->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- 下单参数 -->

                      <!-- <div class="placeOrder">
                        <el-form ref="form" label-width="100px">
                        <orderParam
                            :orderParams="productDetail.orderParams"
                            v-model="productDetail.orderParams"
                          >
                          </orderParam> 

                          <el-form-item label="购买数量">
                            区间
                            <template v-if="productDetail.dealQuantityType === 1">
                              <el-input-number v-model="orderForm.quantity" :min="productDetail.dealQuantityMin"
                                :max="productDetail.dealQuantityMax" label="描述文字"></el-input-number>
                            </template>

                            自定义
                            <template v-if="productDetail.dealQuantityType === 2">
                              <el-select v-model="orderForm.quantity" placeholder="请选择">
                                <el-option v-for="item in dealQuantityArray(productDetail.dealQuantityArray)"
                                  :key="item" :label="item" :value="item">
                                </el-option>
                              </el-select>
                            </template>

                            <el-tag type="success" style="margin-left: 10px" v-if="productDetail.inventoryState">
                              {{ productDetail.inventoryState }}
                            </el-tag>
                          </el-form-item>
                        </el-form>
                      </div> -->

                      <div style="margin-left: 10px">
                        <div
                          class="product_tips"
                          @click="dialogShippingAgreement = true"
                        >
                          我已经阅读，理解并接受
                          <span style="color: #1ba4ff; cursor: pointer">
                            《虚拟商品发货规则》
                          </span>
                        </div>

                        <div style="margin-top: 20px">
                          <el-button
                            type="primary"
                            @click="createOrder()"
                            style="padding: 16px 120px"
                          >
                            立即购买
                            <!-- ￥{{
                              $calculateProductPrice(
                                orderForm.quantity,
                                productDetail.price,
                                productDetail.orderParams
                              )
                            }} -->
                          </el-button>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>

              <div>
                <el-tabs v-model="activeName" class="tab_detail_box">
                  <el-tab-pane label="商品详情" name="1">
                    <!-- 商品详情 -->
                    <div
                      class="cap-richtext"
                      style="padding: 5px 20px 20px 20px"
                      v-if="productDetail.productDescription"
                    >
                      <span v-html="productDetail.productDescription"></span>
                    </div>
                    <!-- 空详情 -->
                    <div v-else>
                      <el-empty description="暂无商品详情"></el-empty>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="历史订单" name="2">
                    <el-empty description="暂无历史订单~"></el-empty>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="goods-right">
              <div class="goods-merchant">
                <div class="goods-merchant-bg"></div>

                <div class="goods-merchant-main">
                  <a class="goods-merchant-avatar">
                    <img
                      :src="webSetting.detail.webLogo"
                      style="object-fit: contain"
                    />
                    <span class="tag">商</span>
                  </a>
                  <!-- <div class="goods-merchant-name">
                    <a
                      aria-current="page"
                      href="#"
                      class="router-link-active router-link-exact-active name"
                    ></a>
                  </div> -->

                  <!-- <div style="margin-top: 10px">
                    <el-button
                      type="primary"
                      size="medium"
                      style="width: 218px"
                    >
                      进入TA的店铺
                    </el-button>
                  </div> -->
                  <div style="margin-top: 60px">
                    <router-link to="/customer">
                      <el-button
                        type="success"
                        size="medium"
                        style="width: 218px"
                      >
                        联系官方客服
                      </el-button>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="goods-right-goods" style="margin-top: 10px">
                <div class="goods-right-goods-header">
                  <div class="title-bar">
                    <span class="side-title">热销推荐</span>
                  </div>
                  <div class="change">
                    <!-- <div class="icon">
                      <i class="el-icon-edit"></i>
                    </div> -->
                    <!-- <div class="text">换一换</div> -->
                  </div>
                </div>
                <div
                  class="goods-right-goods-list"
                  v-if="recommendLists.length > 0"
                >
                  <div class="wrap active">
                    <div
                      class="item"
                      v-for="(v, k, i) in recommendLists"
                      :key="i"
                      @click="toProductDetails(v.id)"
                    >
                      <a class="inner">
                        <div class="img">
                          <img
                            :src="v.picUrl"
                            class="el-image__inner"
                            style="object-fit: cover"
                          />
                        </div>
                        <div class="info">
                          <div class="title">
                            {{ v.productName }}
                          </div>
                          <div class="info-s">
                            <div class="money">￥{{ v.price }}</div>
                            <div class="sales" v-if="v.salsCount">
                              销量{{ v.salsCount }}
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <el-empty description="暂无推荐商品" v-else></el-empty>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 充值提示 -->
    <el-dialog :visible.sync="popupTipVisible" width="40%" append-to-body>
      <div class="success-dialog-container">
        <div class="title">充值提示</div>

        <div
          class="ql-editor"
          style="padding: 30px"
          v-html="productDetail.popupTip"
        />
      </div>
    </el-dialog>

    <el-dialog
      title="商品分享"
      :visible.sync="qrcodeVisible"
      width="30%"
      append-to-body
    >
      <vue-canvas-poster
        v-if="!posterImg"
        :painting="painting"
        style="width: 100%"
        @success="success"
      ></vue-canvas-poster>

      <img v-else :src="posterImg" style="width: 100%" />
    </el-dialog>

    <el-dialog
      title="发货协议"
      :visible.sync="dialogShippingAgreement"
      center
      append-to-body
    >
      <div v-html="xieyiObj.shippingAgreement" style="padding: 10px"></div>
    </el-dialog>

    <el-dialog
      destroy-on-close
      append-to-body
      :visible.sync="levelVisible"
      class="el_dialog_radius"
      width="40%"
      title="等级价格"
      custom-class="centered-dialog"
    >
      <div>
        <div style="margin-bottom: 20px; text-align: center">
          您当前的等级：{{ levelName }}
        </div>
        <el-table :data="levelData" border style="width: 100%">
          <el-table-column prop="levelName" label="等级" align="center">
          </el-table-column>
          <el-table-column prop="price" label="价格" align="center">
          </el-table-column>
        </el-table>

        <router-link to="/user_info">
          <div style="padding: 15px">
            <van-button type="info" round block>立即升级</van-button>
          </div>
        </router-link>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { Toast } from "vant";
import { queryProductDetail } from "@/api/index";

import { getSysXieYi } from "@/api/index";
// import orderParam from "@/components/orderParam.vue";
import { queryProductLevelPrice } from "@/api/product";

export default {
  components: {
    // Header,
    // orderParam,
  },
  data() {
    return {
      srcList: [],

      levelData: [],
      levelVisible: false,
      selectSkuId: undefined,
      activeName: "1",
      dialogShippingAgreement: false,
      qrcodeVisible: false,
      painting: {
        width: "550px",
        height: "876px",
        background: require("@/assets/image/1703344720899.png"),
        views: [
          {
            type: "image",
            url: "",
            css: {
              top: "0px",
              left: "25px",
              borderRadius: "0px",
              width: "500px",
              height: "500px",
            },
          },
          {
            type: "text",
            text: ``,
            css: {
              top: "520px",
              left: "36px",
              width: "500px",
              color: "#000000",
              fontSize: "22px",
            },
          },
          {
            type: "text",
            text: `2`,
            css: {
              top: "730px",
              left: "50px",
              width: "500px",
              color: "#000000",
              fontSize: "26px",
            },
          },
          {
            type: "qrcode",
            content: "",
            css: {
              bottom: "90px",
              right: "76px",
              color: "#000",
              background: "#fff",
              width: "100px",
              height: "100px",
              borderWidth: "5px",
              borderColor: "#fff",
            },
          },
          {
            type: "text",
            text: `微信扫码识别`, // 后端返回路径，这里特别坑，想象不到的bug，oss或者自己服务都允许跨域了，但在手机上还是会有出现cors跨域问题，可能是缓存问题，我们在路径上加个时间戳就好了0.0
            css: {
              bottom: "50px",
              right: "72px",
              color: "rgba(255,255,255,255)",
              fontSize: "18px",
            },
          },
          {
            type: "image",
            url: "",
            css: {
              width: "120px",
              height: "31px",
            },
          },
          // ....
        ],
      },
      popupTipVisible: false,
      kefuStatus: false,
      orderPopupStatus: false,
      productDetail: {},
      orderForm: {
        //收件人姓名
        // deliveryPerson: null,
        //县编码
        // deliveryCountry: "",
        //省编码
        // deliveryProvince: null,
        //市编码
        // deliveryCity: null,
        //省名称
        // deliveryProvinceName: "",
        //城市名称
        // deliveryCityName: "",
        //地址
        // deliveryAddress: "",
        //手机号
        // deliveryPhone: "",
        //运费模版
        // deliveryConfigId: 1,
        // deliveryConfig: "",
        //成交平台 0:web 1:ios 2:android 3:wx
        // dealPlatform: 1,
        //备注
        // deliveryRemark: "",
        //邮编
        // deliveryPostcode: "",
        //充值账号
        //充值数量
        quantity: undefined,
        //商品id
        productId: null,
        orderParams: [],
        stockId: undefined,
      },
      // contactQRUrl: "",
      // contactQQ: "",
      // contactWeChat: "",
      productId: null,
      xieyiObj: {},
      recommendLists: [],
      newProductId: undefined,
      //详情状态 true为正常 false
      detailStatus: true,
      posterImg: undefined,
    };
  },

  computed: {
    ...mapGetters({}),
    ...mapState({
      productDetail: (state) => state.index.productDetail,
      orderId: (state) => state.order.orderId,
      webSetting: (state) => state.index.webSetting,
      recommendList: (state) => state.index.recommendList,
      setting: (state) => state.index.setting,
      levelName: (state) => state.user.levelName,
    }),
  },
  created() {
    this.loadXieYi();
    // 初始化时执行一次刷新数据的操作
    this.refreshData();
  },
  watch: {
    "$route.params.id": function (newId, oldId) {
      // 检测到id参数变动时执行的操作
      if (newId !== oldId) {
        // 执行刷新参数的操作
        this.refreshData();
      }
    },
  },

  mounted() {},
  methods: {
    dealQuantityArray(value) {
      // this.orderForm.quantity = value[0];
      return value.split(",");
    },

    // 海报生成
    success(src) {
      console.log("=============================" + src);
      this.posterImg = src;
    },

    refreshData() {
      // 根据新的参数值刷新数据
      this.newProductId = this.$route.params.id;

      // 加载商品详情
      this.getProductDetail();

      //加载推荐商品
      this.initRecommendProduct();
    },

    //加载推荐商品
    initRecommendProduct() {
      let data = { offset: 0, limit: 5 };
      this.$store.dispatch("index/queryRecommendList", data).then(() => {
        this.recommendLists = this.recommendList;
      });
    },

    loadXieYi() {
      new Promise((resolve, rejust) => {
        getSysXieYi()
          .then((res) => {
            //主站
            this.xieyiObj = res.result;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 等级差价展示
     */
    openLevelVisible() {
      let data = { productId: this.newProductId };
      queryProductLevelPrice(data)
        .then((res) => {
          this.levelData = res.result;
          this.levelVisible = true;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          // 无论成功失败都会执行的逻辑
        });
    },

    /**
     * 分享商品
     */
    shareProducts() {
      this.qrcodeVisible = true;
      this.painting.views[0].url = this.productDetail.pictureList[0].url;
      this.painting.views[1].text = this.productDetail.productName;
      this.painting.views[2].text = "￥" + this.productDetail.price.toString();
      this.painting.views[3].content = window.location.href;
    },

    //联系客服
    lianxikefu() {
      this.$router.push("/contact");
    },

    //获取商品详情
    async getProductDetail() {
      let data = { product_id: this.newProductId };
      const response = await queryProductDetail(data);

      if (response.result) {
        this.productDetail = response.result;
        if (this.productDetail.popupTip) {
          this.popupTipVisible = true;
        }

        //设置网站标题
        document.title = response.result.productName;
      } else {
        this.detailStatus = false;
      }
    },

    //提交订单,弹出框输入账号
    purchaseGoods() {
      this.orderPopupStatus = true;
    },

    //提交订单
    createOrder() {
      // this.orderForm.productId = this.productDetail.id;
      // this.orderForm.orderParams = this.productDetail.orderParams;
      // this.orderForm.stockId = this.productDetail.productStock.id;
      // this.$store
      //   .dispatch("order/create", this.orderForm)
      //   .then(() => {
      //     this.$router.push({
      //       path: "/confirmOrder",
      //       query: { orderId: this.orderId },
      //     });
      //   })
      //   .catch(() => {});

      this.$router.push({
        path: "/confirmOrder",
        query: {
          productId: this.productDetail.id,
          quantity: this.orderForm.quantity,
        },
      });
    },

    toIndex() {
      this.$router.push("/index");
    },

    /**
     * 点击放大图片
     */
    handleImageClick(img) {
      const images = [img];
      this.srcList = images;
    },

    /**
     * 分享商品
     */
    shareGoods() {
      // 复制邀请链接

      const input = document.createElement("input");
      input.setAttribute("value", window.location.href);
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      Toast.success({
        message: "已复制到剪贴板",
      });
    },

    //跳转商品详情界面
    toProductDetails(id) {
      this.$router.push(`/pgoodsDetail/${id}`);
    },

    goBackPage() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.placeOrder {
  /* border: 1px solid #dcdee2; */
  /* border-color: #e8eaec; */
  align-items: flex-end;
  /* padding: 10px; */
  /* background: rgb(0 0 0 / 3%); */
  border-radius: 5px;
  padding-top: 20px;
  padding-right: 50px;
}

.product_top_lan {
  width: fit-content;
  height: 30px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f7f7;
  /* border: 1px solid #ddd; */
  font-size: 12px;
  color: #999;
  border-radius: 4px;
  margin-left: 12px;
  cursor: pointer;
  transition: 0.3s;
}

/* 新的 */
.goods {
  margin-top: 20px;
  width: 100%;
  justify-content: space-between;
}

.goods-info {
  width: 100%;
  /* display: flex; */
  justify-content: space-between;
  padding: 20px 20px 40px;
  background: #fff;
  border-radius: 10px;
}

.goods-info-images {
  width: 100%;
}

.goods-info-images-main img,
.goods-info-images-main video {
  width: 100%;
  height: 100%;
}

.goods-info-images-main video {
  background: #000;
}

.goods-info-images-list {
  position: relative;
  margin-top: 10px;
  width: 100%;
  height: 64px;
  z-index: 1;
}

.goods-info-images-list-container {
  width: calc(100% + 4px);
  height: 64px;
  padding: 2px 2px 0;
  margin: -2px;
  overflow-x: scroll;
  transition: 0.3s;
  z-index: 1;
}

.goods-info-images-list-container::-webkit-scrollbar {
  display: none;
}

.goods-info-images-list-wrap {
  width: fit-content;
  display: flex;
  align-items: center;
  transition: 0.3s;
}

.goods-info-images-list-item {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  -webkit-user-select: none;
  user-select: none;
  background: #ddd;
}

.goods-info-images-list-item:last-child {
  margin-right: 0;
}

.goods-info-images-list-item img,
.goods-info-images-list-item video {
  width: 100%;
  height: 100%;
}

.goods-info-images-list-left-btn,
.goods-info-images-list-right-btn {
  position: absolute;
  top: 2px;
  width: 14px;
  height: calc(100% - 4px);
  background: rgb(0 0 0 / 50%);
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;
  z-index: 2;
}

.goods-info-images-list-left-btn {
  left: 0;
}

.goods-info-images-list-right-btn {
  right: 0;
}

.goods-info-images-list-left-btn:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #fff;
}

.goods-info-images-list-right-btn:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #fff;
}

.goods-info-images-list-left-btn:hover,
.goods-info-images-list-right-btn:hover {
  background: rgb(0 0 0 / 80%);
}

.goods-info-images-list:hover .goods-info-images-list-left-btn,
.goods-info-images-list:hover .goods-info-images-list-right-btn {
  display: flex;
}

.goods-info-show {
  margin-top: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.goods-info-show .left {
  height: 26px;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-size: 12px;
  overflow: hidden;
  color: #3b8cfe;
}

.goods-info-show .left .label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 100%;
  background: #f7f7f7;
  border-right: 1px solid #ddd;
}

.goods-info-show .left .text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  height: 100%;
}

.goods-info-show .right {
  display: flex;
  align-items: center;
}

.goods-info-show .right .btn {
  height: 26px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #999;
  background: #f7f7f7;
  border-radius: 4px;
  transition: 0.2s;
  cursor: pointer;
}

.goods-info-show .right .btn:hover {
  color: var(--el-color-primary);
  background: var(--el-color-primary-light-9);
}

.goods-info-show .right .btn .icon {
  width: 14px;
  height: 14px;
  display: flex;
  margin-right: 3px;
}

.goods-info-right {
  /* width: 560px; */
}

.goods-info-title {
  /* margin-bottom: 20px; */
  font-size: 22px;
  line-height: 30px;
  font-weight: 900;
  color: #333;
}

.goods-info-tags {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.goods-info-tags .tag-type {
  width: 110px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
}

.goods-info-tags .tag-type.cm {
  background: #009688;
}

.goods-info-tags .tag-type.zc {
  background: #e91e63;
}

.goods-info-tags .tag-type.dc {
  background: #ff9800;
}

.goods-info-tags .tag-type span {
  padding-left: 14px;
  position: relative;
}

.goods-info-tags .tag-type span:after {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  left: -2px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  border: 2px solid #fff;
}

.tag-item {
  margin-left: 16px;
  padding: 0 12px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 14px;
}

.goods-info-tags .tag-tj {
  color: #0080ff;
  background: #e5f2ff;
}

.goods-info-tags .tag-zy {
  color: #8bc34a;
  background: #f0fae4;
}

.goods-info-tags .tag-xj {
  color: #f44336;
  background: #ffebee;
}

.goods-info-point {
  width: 100%;
  height: 45px;
  padding: 0 10px;
  background-image: url(../../assets/price-back.410a300c.png);
  background-color: var(--el-color-primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.goods-info-point.check-price {
  margin-bottom: 10px;
}

.goods-info-point-left {
  display: flex;
  align-items: center;
}

.goods-info-point-left .label {
  font-size: 14px;
  margin-top: 8px;
  color: #fff;
}

.goods-info-point-left .money {
  font-size: 28px;
  color: #fff;
}

.goods-info-point-left .price {
  margin-left: 20px;
  font-size: 13px;
  color: #fff;
  text-decoration: line-through;
}

.goods-info-point-right {
  display: flex;
  align-items: center;
}

.goods-info-point-right .sales {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 0 8px;
  font-size: 12px;
  border: 1px solid #fff;
  color: #fff;
}

.goods-info-point-right .level-price {
  margin-right: 10px;
  display: flex;
  align-items: center;
  height: 24px;
  padding: 0 8px;
  font-size: 12px;
  border: 1px solid #fff;
  color: #fff;
  cursor: pointer;
  transition: 0.2s;
}

.goods-info-point-right .level-price:hover {
  background: #fff;
  color: var(--el-color-primary);
}

.goods-info-check-price {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  padding: 0 20px;
  line-height: 30px;
  color: #f44336;
  background: #ffebee;
  margin-bottom: 30px;
}

.goods-info-check-price .icon {
  width: 14px;
  height: 14px;
  display: flex;
  margin-right: 4px;
}

.goods-info-item {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  background-color: #f4faff;
  padding: 15px 0px;
}

.goods-info-item.column-top {
  align-items: flex-start;
}

.goods-info-item .label {
  width: 100px;
  text-align: right;
  margin-right: 20px;
  font-size: 14px;
  color: #666;
}

.goods-info-item.column-top .label {
  line-height: 36px;
}

.goods-info-item .wrap {
  flex: 1;
}

.goods-info-item .wrap.flex {
  display: flex;
  align-items: center;
}

.goods-info-priceLimit-list {
  width: 100%;
  display: flex;
  align-items: center;
}

.goods-info-priceLimit-list .item {
  margin-right: 30px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.goods-info-priceLimit-list .item.yes {
  color: #4caf50;
}

.goods-info-priceLimit-list .item.no {
  color: #f44336;
}

.goods-info-priceLimit-list .item .icon {
  width: 14px;
  height: 14px;
  display: flex;
  margin-right: 4px;
}

.goods-info-guarantee {
  width: 100%;
  display: flex;
  align-items: center;
}

.goods-info-guarantee .item {
  position: relative;
  margin-right: 30px;
  padding-left: 14px;
  font-size: 14px;
  color: #666;
}

.goods-info-guarantee .item:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border: 2px solid #2e68e9;
  border-radius: 4px;
}

.goods-info-guarantee .item1 {
  position: relative;
  margin-right: 30px;
  font-size: 14px;
  color: #666;
}

.goods-info-num {
  width: 200px;
  height: 32px;
  display: flex;
  align-items: center;
}

.goods-info-num-btn {
  width: 40px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #333;
  border: 1px solid #ddd;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: 0.2s;
}

.goods-info-num-btn.left {
  border-radius: 4px 0 0 4px;
}

.goods-info-num-btn.right {
  border-radius: 0 4px 4px 0;
}

.goods-info-num-btn:hover {
  color: var(--el-color-primary);
  background: var(--el-color-primary-light-9);
}

.goods-info-num-btn.disabled {
  color: #999;
  cursor: not-allowed;
  background: #eee;
}

.goods-info-num-input {
  width: 128px;
  height: 32px;
  border: none;
  text-align: center;
  font-size: 18px;
  color: #565656;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.goods-info-quota {
  margin-left: 20px;
  font-size: 14px;
  color: #666;
}

.goods-info-input {
  width: 200px;
  height: 32px;
  border: 1px solid #ddd;
  font-size: 14px;
  border-radius: 4px;
  padding-left: 10px;
  transition: 0.2s;
}

.goods-info-input:hover {
  border-color: var(--el-color-primary-light-6);
}

.goods-info-input:focus {
  border-color: var(--el-color-primary);
}

.goods-info-pay {
  width: 100%;
}

.goods-info-pay-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.goods-info-pay-list .item {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  color: #666;
  border-radius: 4px;
  transition: 0.2s;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.goods-info-pay-list .item.active,
.goods-info-pay-list .item:hover {
  border-color: var(--el-color-primary-light-6);
  color: var(--el-color-primary);
  background: var(--el-color-primary-light-9);
}

.goods-info-pay-list .item .icon {
  margin-right: 5px;
  width: 14px;
  height: 14px;
  display: flex;
}

.goods-info-pay-list .item:hover .icon {
  color: #fff;
}

.goods-info-pay-list .item .name {
  font-size: 13px;
}

.goods-info-pay-wrap {
  width: 100%;
  padding: 20px;
  margin-top: 20px;
  background: #f9f9f9;
  border-radius: 6px;
}

.goods-info-pay-wrap-label {
  width: 100%;
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.goods-info-pay-wrap-item {
  width: 100%;
}

.goods-info-pay-wrap-item + .goods-info-pay-wrap-label {
  margin-top: 10px;
}

.goods-info-pay-wrap textarea {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 80px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  background: #fff;
  transition: border-color 0.2s;
}

.goods-info-pay-wrap input {
  width: 100%;
  height: 36px;
  border: 1px solid #ddd;
  padding-left: 10px;
  border-radius: 4px;
  background: #fff;
  transition: border-color 0.2s;
}

.goods-info-pay-wrap textarea:hover,
.goods-info-pay-wrap input:hover {
  border-color: var(--el-color-primary-light-6);
}

.goods-info-pay-wrap textarea:focus,
.goods-info-pay-wrap input:focus {
  border-color: var(--el-color-primary);
}

.goods-info-btns {
  width: 100%;
  margin-top: 30px;
  display: flex;
}

.goods-info-pay-btn {
  min-width: 200px;
  height: 46px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--el-color-primary);
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 3px 8px var(--el-color-primary-light-7);
  transition: 0.2s;
}

.goods-info-pay-btn .icon {
  margin-right: 6px;
  width: 16px;
  height: 16px;
  display: flex;
}

.goods-info-pay-btn:hover {
  background: var(--el-color-primary-light-3);
}

.goods-info-pay-btn.disabled {
  cursor: not-allowed;
  background: #ddd;
  box-shadow: none;
}

.goods-info-dome-btn {
  width: 120px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--el-color-primary);
  border: 1px solid var(--el-color-primary);
  margin-left: 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: 0.2s;
}

.goods-info-dome-btn:hover {
  color: #fff;
  background: var(--el-color-primary);
}

.goods-info-dome-btn .icon {
  margin-right: 3px;
  width: 18px;
  height: 18px;
  display: flex;
}

.goods-info-protocol {
  display: flex;
  align-items: center;
  margin-top: 14px;
}

.goods-info-protocol-radio {
  width: 16px;
  height: 16px;
  border: 4px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ddd;
  border-radius: 16px;
  cursor: pointer;
  margin-right: 6px;
  transition: 0.2s;
}

.goods-info-protocol-radio:hover {
  border-color: #bbb;
}

.goods-info-protocol-radio.active {
  border-color: var(--el-color-primary);
}

.goods-info-protocol-radio.active:hover {
  border-color: var(--el-color-primary-light-3);
}

.goods-info-protocol-label {
  font-size: 14px;
  color: #666;
}

.goods-info-protocol-label span {
  color: var(--el-color-primary);
  cursor: pointer;
}

.goods-info-protocol-label span:hover {
  color: var(--el-color-primary-light-3);
}

.goods-message {
  margin-top: 30px;
  width: 100%;
  background: #fff;
}

.goods-message-tabs {
  width: 100%;
  height: 50px;
  padding: 0 50px;
  display: flex;
  border-bottom: 2px solid #eee;
}

.goods-message-tab {
  position: relative;
  height: 100%;
  line-height: 50px;
  font-size: 14px;
  color: #333;
  transition: 0.2s;
  cursor: pointer;
  margin-right: 40px;
}

.goods-message-tab.active,
.goods-message-tab:hover {
  color: var(--el-color-primary);
}

.goods-message-tab.active:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--el-color-primary);
}

.goods-message-content {
  width: 100%;
  padding: 20px;
}

.goods-merchant {
  position: relative;
  width: 100%;
  height: 280px;
  background: #fff;
  border: 1px solid #e6e6e6;
  z-index: 1;
}

.goods-merchant-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    var(--color1, #e6f5ec),
    var(--color2, #9bd9b4)
  );
  opacity: 0.5;
  overflow: hidden;
  z-index: -1;
}

.goods-merchant-qrcode {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  z-index: 2;
}

.goods-merchant-qrcode-btn {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.goods-merchant-qrcode-btn .icon {
  width: 30px;
  height: 30px;
  display: flex;
}

.goods-merchant-qrcode-btn:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 40px solid transparent;
  border-right: none;
  border-bottom: none;
  border-left: 40px solid var(--el-color-primary-light-9);
}

.goods-merchant-qrcode-wrap {
  position: absolute;
  top: 0;
  left: -220px;
  width: 220px;
  height: 220px;
  background: #fff;
  box-shadow: 0 6px 18px -2px #0003;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.goods-merchant-qrcode:hover .goods-merchant-qrcode-wrap {
  display: flex;
}

.goods-merchant-qrcode-wxcode {
  width: 140px;
  height: 140px;
  overflow: hidden;
}

.goods-merchant-qrcode-wxcode img {
  width: 100%;
  height: 100%;
}

.goods-merchant-qrcode-text {
  width: 140px;
  height: 22px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--el-color-primary-light-9);
  border-radius: 4px;
  font-size: 12px;
  color: #666;
}

.goods-merchant-qrcode-text .icon {
  width: 14px;
  height: 14px;
  display: flex;
  margin-right: 3px;
}

.goods-merchant-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 40px;
  z-index: 1;
}

.goods-merchant-avatar {
  position: relative;
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 0 4px #fff;
}

.goods-merchant-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.goods-merchant-avatar .tag {
  position: absolute;
  bottom: -13px;
  left: 50%;
  transform: translate(-50%);
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: var(--background-color, rgb(43, 125, 207));
  border: 2px solid #fff;
  color: #fff;
  text-align: center;
  line-height: 23px;
}

.goods-merchant-name {
  margin-top: 25px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.goods-merchant-name .name {
  font-size: 16px;
  color: #333;
  transition: 0.2s;
}

.goods-merchant-name .name:hover {
  color: var(--el-color-primary);
}

.goods-merchant-btn {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-radius: 4px;
  transition: 0.2s;
  cursor: pointer;
}

.goods-merchant-btn .icon {
  width: 16px;
  height: 16px;
  display: flex;
}

.goods-merchant-btn.light {
  color: var(--el-color-primary);
  background: #fff;
  border: 1px solid var(--el-color-primary);
  margin-bottom: 10px;
}

.goods-merchant-btn.light:hover {
  background: var(--el-color-primary);
  color: #fff;
}

.goods-merchant-btn.dark {
  color: #fff;
  background: var(--el-color-primary);
  box-shadow: 0 3px 6px var(--el-color-primary-light-7);
}

.goods-merchant-btn.dark:hover {
  background: var(--el-color-primary-light-3);
}

.goods-right-tip {
  margin-top: 20px;
  width: 100%;
  background: #fff;
}

.goods-right-tip-content {
  padding: 20px;
  text-indent: 20px;
  line-height: 1.5;
  color: #666;
}

.goods-right-tip-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.goods-right-tip-btn .btn {
  padding: 0 20px;
  height: 30px;
  display: flex;
  align-items: center;
  color: var(--el-color-primary);
  font-size: 13px;
  transition: 0.2s;
  background: var(--el-color-primary-light-9);
  border-radius: 4px;
}

.goods-right-tip-btn .btn:hover {
  color: var(--el-color-primary-light-3);
}

.goods-right-tip-btn .btn .icon {
  width: 18px;
  height: 18px;
  display: flex;
  margin-right: 2px;
}

.goods-right-goods {
  width: 100%;
  border-radius: 5px;
  background: #fff;
}

.goods-right-goods-header,
.goods-right-tip-title {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 16px;
  border-bottom: 1px solid #eee;
}

.goods-right-goods-header .title,
.goods-right-tip-title .title {
  position: relative;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  color: #333;
}

.goods-right-goods-header .title:after,
.goods-right-tip-title .title:after {
  position: absolute;
  content: "";
  top: 14px;
  left: -20px;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: transparent;
}

.goods-right-goods-header .change {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #666;
  -webkit-user-select: none;
  user-select: none;
  transition: 0.2s;
}

.goods-right-goods-header .change:hover {
  color: var(--el-color-primary);
}

.goods-right-goods-header .change .icon {
  width: 12px;
  height: 12px;
  display: flex;
}

.goods-right-goods-header .change .text {
  margin-left: 4px;
  font-size: 12px;
}

.goods-right-goods-list {
  width: 100%;
}

.goods-right-goods-list .wrap {
  width: 100%;
  display: none;
}

.goods-right-goods-list .wrap.active {
  display: block;
}

.goods-right-goods-list .item {
  width: 100%;
  padding: 20px;
}

.goods-right-goods-list .item + .item {
  border-top: 1px solid #eee;
}

.goods-right-goods-list .item .inner {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
}

.goods-right-goods-list .item .img {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 6px;
}

.goods-right-goods-list .item .img img {
  width: 100%;
  height: 100%;
}

.goods-right-goods-list .item .info {
  width: calc(100% - 92px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.goods-right-goods-list .item .title {
  font-size: 13px;
  color: #333;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.goods-right-goods-list .inner:hover .title {
  color: var(--el-color-primary);
}

.goods-right-goods-list .item .info-s {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.goods-right-goods-list .item .money {
  font-size: 14px;
  color: #ff2d1e;
}

.goods-right-goods-list .item .info-s .sales {
  font-size: 12px;
  color: #666;
}

.goods-poter-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 777;
}

.goods-poter-wrap-outer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 20%);
  z-index: 1;
}

.goods-poter-wrap-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translate(-50%);
  width: 300px;
  border-radius: 8px;
  z-index: 2;
}

.goods-poter-wrap-wrap-close {
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translate(-16px);
  width: 32px;
  height: 32px;
  background: rgb(0 0 0 / 50%);
  color: #fff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;
}

.goods-poter-wrap-wrap-close:hover {
  background: rgb(0 0 0 / 70%);
}

.goods-poter-wrap-wrap-close .icon {
  display: block;
  width: 16px;
}

.goods-null {
  margin-top: 30px;
  width: 100%;
  padding: 50px;
  background: #fff;
  border-radius: 8px;
}

.goods-null-icon {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.goods-null-icon .icon {
  display: block;
  width: 200px;
}

.goods-null-btn {
  margin: 20px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 40px;
  font-size: 14px;
  color: var(--ksy--diycolor1);
  border: 1px solid var(--ksy--diycolor1);
  border-radius: 4px;
  transition: 0.3s;
}

.goods-null-btn:hover {
  color: #fff;
  background: var(--ksy--diycolor1);
}

.goods-message-content {
  margin-top: 20px;
  width: 100%;
  line-height: 1.5;
  word-wrap: break-word;
  overflow: hidden;
}

.goods-message-content img {
  max-width: 100%;
}

.goods-message-content a {
  color: var(--ksy--diycolor1);
}

.goods-message-content a:hover {
  color: var(--ksy--diycolor2);
}

.product_tips {
  font-size: 14px;
  color: #666;
  /* margin-left: 20px; */
  margin-top: 40px;
  position: relative;
  text-indent: 20px;
}

.product_tips::after {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  width: 12px;
  height: 12px;
  border: 2px solid #fff;
  border-radius: 10px;
  background: #59f;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}

.skuCate {
  /* margin-bottom: 15px; */
  display: flex;
  /* align-items: center; */
}

.pcSkuCateText {
  width: 100px;
  padding-right: 4px;
  display: inline-block;
}

.skuCateText {
  height: 14px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #7f7f7f;
  line-height: 10px;
  /* margin-top: 9px; */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.skuItemWrapper {
  display: inline-block;
  vertical-align: top;
  /* width: 620px; */
  /* margin-left: -4.5px; */
}

.skuItem {
  min-width: 36px;
  max-width: 300px;
  min-height: 36px;
  display: inline-block;
  vertical-align: top;
  margin-right: 15px;
  /* margin-left: 4.5px; */
  padding: 2px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.06);
  border: 1px solid transparent;
  cursor: pointer;
  text-align: center;
  position: relative;
}

.skuIcon {
  width: 30px;
  height: 30px;
  border-radius: 3px;
}

.skuIcon + .skuValueName {
  max-width: 264px;
  padding-left: 4px;
}

.skuValueName {
  display: inline-block;
  font-size: 14px;
  line-height: 14px;
  color: #333;
  text-overflow: ellipsis;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 294px;
  line-height: 30px;
  vertical-align: middle;
}

.sku_select {
  background-color: #ffcc00;
  /* 这里是选中状态的背景颜色 */
  color: #ffffff;
}

.title-bar {
  position: relative;
}

.side-title {
  font-size: 14px;
  margin-left: 12px;
  line-height: 20px;
}

.side-title:after {
  content: "";
  position: absolute;
  width: 5px;
  height: 20px;
  background: #006eff;
  left: 1px;
  -webkit-box-shadow: 3px 0 10px -1px #13adff;
  box-shadow: 3px 0 10px -1px #13adff;
  border-radius: 2px;
}

/* 美化tabs */
.tab_detail_box {
  padding: 0px 0px;
  margin-top: 10px;
  background-color: #ffffff;
  /* padding: 5px 20px 20px 20px; */
}

::v-deep .tab_detail_box .el-tabs__item {
  position: relative;
  width: 116px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  cursor: pointer;
  padding: 0;
  background: #fff;
}

::v-deep .tab_detail_box .el-tabs__active-bar {
  height: 0px;
}

::v-deep .tab_detail_box .el-tabs__item.is-active {
  border-top: 1px solid #2a8aef;
}

.tab_detail_box .el-tabs__pane {
  padding: 10px;
}

.tab_detail_box .el-tabs__nav {
  padding: 0px;
}

/* .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 15px ;
} */

/* .el-tabs--top .el-tabs__item.is-top:last-child {
  padding-right: 15px ;
} */

.tab_detail_box .el-col-5 {
  width: 20%;
}

/* /deep/.el-carousel__container {
  height: 300px;
} */

.cap-richtext {
  /* padding: 10px; */
  overflow-x: hidden;
}

.cap-richtext img {
  width: 100%;
  height: auto;
  max-width: 100%;
}
</style>
